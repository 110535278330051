import React, { useState, useEffect } from "react"
import styled, { ThemeProvider } from "styled-components"
import { IntlContextProvider } from "gatsby-plugin-intl"

import SEO from "../seo"
import useContent from "../developers/useContent"
import themes from "../developers/theme.json"
import Header from "../developers/Header"
import Footer from "../developers/Footer"
import AcceptCookieForm from "../components/AcceptCookieForm"
import ModalsContainer from "../components/ModalsContainer"
import TopBanner from "../common/TopBanner"
import ContactSalesModal from "../developers/ContactSales"
import { Indent } from "src/uikit"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.bg};
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text};
`

const Subtitle = styled.p`
  font-size: 20px;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 64px;
  color: ${({ theme }) => theme.caption};
`

const PricingContainer = styled.div`
  max-width: 1200px;
  margin: 100px auto;
  padding: 0 24px;
`

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 64px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const PlanCard = styled.div`
  background: ${props => props.featured 
    ? "linear-gradient(255.03deg, #7785FF -0.91%, #6472EF 102.89%)" 
    : props.theme.bg === "#161729" ? "#1D1E31" : props.theme.bg};
  border-radius: 16px;
  padding: 40px;
  width: 100%;
  max-width: 400px;
  box-shadow: ${props => props.theme.bg === "#161729" 
    ? "0 12px 30px rgba(0, 0, 0, 0.5)" 
    : "0 10px 30px rgba(0, 0, 0, 0.08)"};
  display: flex;
  flex-direction: column;
  border: ${props => props.featured 
    ? "none" 
    : `1px solid ${props.theme.headerBorder}`};
`

const PlanName = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: ${props => props.featured ? "#fff" : props.theme.text};
`

const PlanPrice = styled.div`
  font-size: ${props => props.large ? "32px" : "20px"};
  font-weight: ${props => props.bold ? "bold" : "normal"};
  margin-bottom: 24px;
  color: ${props => props.featured ? "#fff" : props.theme.text};
`

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 40px;
  flex-grow: 1;
`

const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  color: ${props => {
    if (props.featured) return "#fff"; 
    return props.theme.caption;
  }};
  
  &:before {
    content: "✓";
    margin-right: 12px;
    color: ${props => {
      if (props.featured) return "#fff";
      return props.theme.bg === "#161729" ? "#8785FF" : props.theme.link;
    }};
    font-weight: bold;
  }
`

const Button = styled.button`
  background: ${props => {
    if (props.featured) return "transparent";
    return props.theme.bg === "#161729" ? "#2E3361" : "transparent";
  }};
  color: ${props => {
    if (props.featured) return "#fff";
    return props.theme.link;
  }};
  border: ${props => {
    if (props.featured) return `2px solid #fff`;
    return `2px solid ${props.theme.link}`;
  }};
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  
  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    background: ${props => {
      if (props.featured) return "rgba(255, 255, 255, 0.1)";
      return props.theme.bg === "#161729" ? "#363B6E" : "rgba(88, 85, 244, 0.1)";
    }};
  }
`

const CommonFeaturesSection = styled.div`
  background: ${props => props.theme.bg === "#161729" ? "#1D1E31" : "#F7F8FA"};
  border-radius: 16px;
  padding: 40px;
  margin-bottom: 64px;
  border: 1px solid ${props => props.theme.headerBorder};
`

const CommonFeaturesTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
  color: ${props => props.theme.text};
`

const CommonFeaturesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const CommonFeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
`

const FeatureTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const FeatureIcon = styled.div`
  display: inline-block;
  margin-right: 16px;
  color: ${props => props.theme.bg === "#161729" ? "#8785FF" : props.theme.link};
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
`

const FeatureHeading = styled.h4`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: ${props => props.theme.text};
`

const FeatureDescription = styled.p`
  font-size: 16px;
  color: ${props => props.theme.caption};
  line-height: 1.5;
  margin: 0;
`

const PricingPage = ({ location }) => {
  const content = useContent()
  const [theme, setTheme] = useState("dark")
  const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false)
  
  // Load theme from localStorage on page load
  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedTheme = localStorage.getItem("dashaTheme")
      if (savedTheme) {
        setTheme(savedTheme)
      }
    }
  }, [])
  
  const handleModalClose = () => setIsContactSalesModalOpen(false)
  const handleModalOpen = () => setIsContactSalesModalOpen(true)
  
  const handleChangeTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light"
    setTheme(newTheme)
    // Save theme preference to localStorage
    if (typeof window !== "undefined") {
      localStorage.setItem("dashaTheme", newTheme)
    }
  }

  const pricingContent = content.pricing

  return (
    <ThemeProvider theme={themes[theme]}>
      <IntlContextProvider>
        <Wrap id="layout">
          <AcceptCookieForm />
          <SEO
            title={pricingContent.meta.title}
            description={pricingContent.meta.description}
            keywords={pricingContent.meta.keywords}
            location={location}
          />
          <TopBanner />
          <Header 
            content={content} 
            openModal={handleModalOpen} 
            theme={theme}
            changeTheme={handleChangeTheme}
          />
          <Indent lg={80} md={80} sm={80} />
          <PricingContainer id="pricing">
            <Title>{pricingContent.title}</Title>
            <Subtitle>{pricingContent.subtitle}</Subtitle>
            
            <PlansContainer>
              {/* Developer Free Plan */}
              <PlanCard>
                <PlanName>{pricingContent.developerPlan.name}</PlanName>
                <PlanPrice large bold>{pricingContent.developerPlan.price}</PlanPrice>
                <FeaturesList>
                  {pricingContent.developerPlan.features.map((feature, index) => (
                    <FeatureItem key={index}>{feature}</FeatureItem>
                  ))}
                </FeaturesList>
                <Button onClick={() => window.location.href = `https://playground.dasha.ai/`}>
                  {pricingContent.developerPlan.buttonText}
                </Button>
              </PlanCard>
              
              {/* Growth Plan */}
              <PlanCard featured>
                <PlanName featured>{pricingContent.growthPlan.name}</PlanName>
                <PlanPrice featured>{pricingContent.growthPlan.priceLabel}</PlanPrice>
                <PlanPrice large bold featured>{pricingContent.growthPlan.price}</PlanPrice>
                <FeaturesList>
                  {pricingContent.growthPlan.features.map((feature, index) => (
                    <FeatureItem featured key={index}>{feature}</FeatureItem>
                  ))}
                </FeaturesList>
                <Button featured onClick={handleModalOpen}>
                  {pricingContent.growthPlan.buttonText}
                </Button>
              </PlanCard>
            </PlansContainer>
            
            {/* Common Features Section */}
            <CommonFeaturesSection>
              <CommonFeaturesTitle>All Plans Include</CommonFeaturesTitle>
              <CommonFeaturesList>
                <CommonFeatureItem>
                  <FeatureTitleRow>
                    <FeatureIcon>⏱</FeatureIcon>
                    <FeatureHeading>One Second Billing Increment</FeatureHeading>
                  </FeatureTitleRow>
                  <FeatureDescription>
                    No minimum call duration. Calls are billed to the exact second, so a 5-second call 
                    is charged as 5 seconds, not rounded up to a minute.
                  </FeatureDescription>
                </CommonFeatureItem>
                
                <CommonFeatureItem>
                  <FeatureTitleRow>
                    <FeatureIcon>💰</FeatureIcon>
                    <FeatureHeading>No Charge for Call Attempts</FeatureHeading>
                  </FeatureTitleRow>
                  <FeatureDescription>
                    You&apos;re only charged for successful connections. If a call attempt doesn&apos;t connect, 
                    there&apos;s no charge.
                  </FeatureDescription>
                </CommonFeatureItem>
              </CommonFeaturesList>
            </CommonFeaturesSection>
          </PricingContainer>
          
          <Footer content={content.footer} />
          
          <ContactSalesModal
            content={{ title: content.header.contactSales }}
            isOpen={isContactSalesModalOpen}
            onClose={handleModalClose}
          />
          <ModalsContainer location={location} />
        </Wrap>
      </IntlContextProvider>
    </ThemeProvider>
  )
}

export default PricingPage 